

.main-settings-wrap {
  height: 100%;
  width: 100%;
  background: black;
  position: absolute;
  padding: 24px;
  box-sizing: border-box;
  user-select: none; }

.main-settings-title {
  color: white;
  font-size: 36px;
  font-weight: 200;
  margin: 10px 0 40px 0; }

.settings-list {
  color: white;
  list-style-type: none;
  margin: 20px 0 0 0;
  padding: 0;

  li {
    margin: 30px 0;
    display: flex;
    align-items: center;
    position: relative;

    svg {
      width: 24px !important;
      font-size: 26px;
 }      //background: red

    span {
      color: #dcdcdc;
      margin-left: 24px;
      font-size: 18px;
      font-weight: 300; }

    div {
      width: 10px;
      height: 10px;
      border-top: 1px solid #717171;
      border-right: 1px solid #717171;
      position: absolute;
      right: 0;
      transform: rotate(45deg); } } }

.settings-line {
  margin: 30px 0;
  background: #262626; }

.input-menu-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  z-index: 1;
  left: 0;
  top: 0;
  padding: 24px;
  transition: .3s;
  overflow-y: scroll;

  &_false {
    left: 100%; } }

.close-input-menu {
  color: white;
  font-size: 18px;
  right: 24px;
  position: absolute; }

.input-menu .reg-window {
  max-width: 100%; }

/* apps settings */
.apps-settings-wrap {
  width: 360px;
  max-width: 100%;
  padding-top: 30px; }

.app-block {
  width: 100%;
  background: #22262a;
  margin-bottom: 30px;
  border-radius: 8px;
  padding: 14px;

  &__icon {
    color: #262c31;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    font-size: 14px; }

  .reg-window__item-block, .reg-window__button {
    margin: 6px 0; }

  .reg-window__err-item {
    margin: 6px 0; } }

.user-settings-block {
  font-size: 16px;
  color: #d2d2d2;

  .theme {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 14px;

    .active {
      transform: scale(.92); }

    section {
      width: 60px;
      height: 80px;
      border-radius: 3px;
      position: relative;
      box-shadow: 0 0 5px rgba(0, 0, 0, .3);

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 30px;
        border-radius: 0 0 3px 3px; } }

    section:nth-child(1) {
      background: #FDF6F0;
      &::before {
        background: #F5C6AA; } }

    section:nth-child(2) {
      background: #F4EEFF;
      &::before {
        background: #A6B1E1; } }

    section:nth-child(3) {
      background: #D7FBE8;
      &::before {
        background: #62D2A2; } }

    section:nth-child(4) {
      background: #fce9e3;
      &::before {
        background: #FFBBCC; } } } }
