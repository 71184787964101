@import "../Components/login/styles.sass";
@import "../Components/projects/styles.sass";
@import "../Components/main/styles.sass";
@import "../tools/style.sass";
@import "../Components/lending/lending.sass";
@import "../Components/docs/styles.sass";

html, body {
  background: #2c3338;
  height: 100vh; }
