
/*   ---==== Presets ====--- */

@import "./interpreter/presets/block.sass";


/*   ---==== Modal wrap ====---   */

.modal-wrap {
  padding: 100px 0 0 0;
  margin: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media all and (max-width: 500px) {
    padding: 0;
    align-items: flex-start; } }

.modal-wrap .modal-content-wrapper {
  position: relative;
  animation: modal-wrap-animation .2s linear forwards;
  z-index: 1001;

  /* no important styles */
  width: 80%;
  max-width: 1000px;
  min-height: 100px;
  background: #2c3338;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .3);

  @media all and (max-width: 500px) {
    width: 100%;
    border-radius: 0 0 16px 16px; } }


@keyframes modal-wrap-animation {
  from {
    margin-top: -100%;
    transform: scale(.6); }
  to {
    margin-top: 0;
    transform: scale(1); } }



/*   ---==== Button ====---   */

.preset-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 400;
  color: #262c31;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background: black;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    opacity: 0;
    cursor: pointer;
    transition: .12s; }

  &:hover {
    &::after {
      opacity: 0.1; } }

  &:active {
    &::after {
      opacity: 0.22; } }


  &_xs {
    border-radius: 4px;
    font-size: 11px;
    height: 20px;
    padding: 0 6px;
    margin: 6px;

    &::after {
      border-radius: 4px; } }

  &_sm {
    font-size: 13px;
    height: 30px;
    padding: 0 10px;
    margin: 8px; }

  &_md {
    font-size: 16px;
    height: 40px;
    padding: 0 16px;
    margin: 10px; }

  &_lg {
    font-size: 18px;
    height: 50px;
    padding: 0 22px;
    margin: 12px; }

  &_xl {
    font-size: 20px;
    height: 60px;
    padding: 0 26px;
    margin: 14px; } }



/*   ---==== Interpreter ====---   */

.interpreter {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: white; }



/*   ---==== Message ====---   */

.message-box {
  position: absolute;
  width: 200px;
  right: 20px;
  top: 20px;
  animation: message ease-in 4s forwards;
  z-index: 10;

  &_center {
    animation: none;
    width: 300px;
    right: calc(50% - 150px);
    top: 60px; }

  &__text {
    font-weight: bold;
    font-size: 16px;
    background: rgba(0, 0, 0, .12);
    padding: 10px 15px; }

  &__under-text {
    font-size: 14px;
    background: rgba(0, 0, 0, .12);
    padding: 0 15px 10px 15px; }

  &__icon {
    font-size: 40px;
    text-align: center;
    padding: 20px 30px; } }

@keyframes message {
  0% {
    opacity: 0; }

  10% {
    opacity: 1; }

  90% {
    opacity: 1; }

  100% {
    opacity: 0; } }



/*   ---==== Alerts ====---   */

/* alert */

#system-alert-wrap, #system-pop-up-wrap {
  position: fixed;
  background: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  top: 0;
  right: 0; }

#system-alert, #system-pop-up {
  position: absolute;
  padding: 0;
  width: 500px;
  background: white;
  border-radius: 5px;
  border: 1px solid lightgray;

  @media all and (max-width: 500px) {
    width: calc(100% - 10px); }

  &__main {
    padding: 14px; }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: rgba(0, 0, 0, .08);
    padding: 3px;

    div {
      color: black;
      height: 26px;
      text-align: center;
      border-radius: 2px;
      box-shadow: 0 -3px rgba(0, 0, 0, .2) inset;
      margin: 3px;
      padding: 1px 8px 0 8px;
      font-weight: 400;
      font-size: 14px;
      background: #ffffff;
      user-select: none;
      cursor: pointer; } } }

/* pop up */

.top-index {
  position: relative;
  z-index: 1001;
  pointer-events: none;

  @media all and (max-width: 500px) {
    &::before {
      content: '';
      position: absolute;
      margin: auto;
      width: 20px;
      height: 20px;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      top: -35px;
      left: 10px;
      transform: rotate(-45deg); } } }

#system-pop-up-wrap {
  background: rgba(0, 0, 0, .5); }

#system-pop-up {
  width: auto;
  max-width: 300px;
  border-radius: 2px;
  border: 1px solid white;

  @media all and (max-width: 500px) {
    min-width: calc(100% - 10px) !important;
    top: 5px !important;
    left: 5px !important; } }

#system-pop-up-corner-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1; }

#system-pop-up-corner {
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  position: absolute;
  background: white;

  @media all and (max-width: 500px) {
    display: none; } }
