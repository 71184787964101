
.title-item {
  background: white;
  text-align: center;
  padding-top: .5px;
  border-radius: 5px;
  box-shadow: 0 -3px rgba(0, 0, 0, .2) inset;
  cursor: pointer;
  box-sizing: border-box;
  color: black;
  text-decoration: none;

  &:hover {
    color: black;
    text-transform: none;
    text-decoration: none;

    box-shadow: none;
    margin-top: 3px; }

  &_active {
    background: #feca57; } }
