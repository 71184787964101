/*  <div class="appex-preset-block">
      <div class="appex-preset-block__item">
        [[Switch id="switch-3"]]
      </div>
      <div class="appex-preset-block__label"> лампа 3 </div>
    </div>*/

.appex-preset-block {
  background: #ecf0f1;
  padding: 5px; }

.appex-preset-block__item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px; }

.appex-preset-block__label {
  text-align: center; }
