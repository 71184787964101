
.super-button {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid transparent;
  background: black;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;

  &__knob {
    width: 20px;
    height: 20px;
    color: white; } }

.super-button-slider {
  right: 70px;
  width: 60px;
  border-radius: 20px; }

#swipe-wrap {
  width: calc(100% - 70px);
  height: 50px;
  position: absolute;
  margin: auto;
  bottom: 0;
  left: 0;
  //background: black
  //opacity: 0.1
  z-index: 0; }
