
$spin-size: 10px;

.loading-wrap {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.25); }

.loading {
  width: 120px;
  height: 120px;
  background: #4b6584;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    width: 80px;
    margin-left: 11px;
    margin-top: 21px;
    animation: light 5s linear infinite; } }


@keyframes light {
  0% {
    filter: drop-shadow( 0 0 1px white ); }
  50% {
    filter: drop-shadow( 0 0 6px white ); }
  100% {
    filter: drop-shadow( 0 0 1px white ); } }
