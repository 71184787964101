/* cards */
.cards {
  padding: 10px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap; }

@media all and (max-width: 500px) {
  .cards {
    padding: 0;
    display: flex;
    justify-content: space-evenly; } }

/* Plus card */
.project-card_plus {
  width: 100px;
  height: 100px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: .12s;
  cursor: pointer;
  background: #16a085; }

.room-card_plus {
  background: #2980b9; }

.project-card-wrap:hover {
  .project-card_plus {
    transform: scale(1.16);
    background: #1abc9c; }

  .room-card_plus {
    background: #3498db; } }

.project-card__plus-wrap svg {
  font-size: 32px;
  display: block;
  margin: auto;
  color: #262c31;
  transition: .12s;
  cursor: pointer; }

.project-card__down-text {
  position: absolute;
  bottom: 2px;
  font-size: 14px;
  color: #838a91;

  @media all and (max-width: 500px) {
    font-size: 18px; } }

hr {
  background: #3b4148;
  margin: 0; }

.projects-wrap {
  padding: 10px 0; }
