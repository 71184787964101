@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400&display=swap');

.reg-window {
  width: 360px;
  max-width: 90%;
  // border: .5px solid #4f4f4f
  box-sizing: border-box;
  font-family: roboto; }

.reg-window_center {
  margin: auto; }

/* Input */

.reg-window__input {
  width: 100%;
  height: 100%;
  outline: none;
  display: block;
  background: #3b4148;
  border: none;
  color: #dddada;
  font-size: 18px;
  padding-left: 10px;
  box-sizing: border-box;
  border-radius: 0 5px 5px 0; }

.reg-window__input-item {
  display: flex;
  justify-content: center;
  width: 100%; }

.reg-window__item-block {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px; }

.reg-window__item-block:first-child {
  width: 60px;
  background: #363b41;
  border-radius: 5px 0 0 5px;
  color: #606468; }

.reg-window__item-block:last-child {
  width: calc( 100% - 60px );
  border-radius: 0 5px 5px 0;
  justify-content: flex-end; }

/* Text */

.reg-window__text-item {
  color: #dddada;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center; }

/* Button */

.reg-window__button {
  height: 52px;
  width: 100%;
  margin-top: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  user-select: none;
  cursor: pointer;
  transition: .1s;

  &:active {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); } }

.reg-window__button_blue {
  background: #3dc1d3;

  &:hover {
    background: #35abbb; }

  &:active {
    background: #28808b; } }

.reg-window__button_red {
  background: #ff7979;

  &:hover {
    background: #d86767; }

  &:active {
    background: #ad5252; } }

.reg-window__button_green {
  background: #1abc9c;

  &:hover {
    background: #18a88c; }

  &:active {
    background: #169a80; } }


/* Appex logo */
.appex-logo {
  filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, 0.1));
  width: 82px;
  margin-right: -10px; }

.logo-text {
  font-family: 'comfortaa', roboto, monospace;
  font-size: 40px;
  color: white;
  position: relative;
  text-shadow: .5px .5px .5px rgba(0, 0, 0, 0.1); }

/* Under text */
.reg-window__under-text {
  margin-top: 30px;
  color: #838a91;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    margin-left: 6px;
    color: #3498db;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: #2980b9; } } }

.reg-window__under-text_not-first {
  margin-top: 2px; }

/* errs */
.reg-window__err-item {
  width: calc( 100% - 76px );
  float: right;
  background: #3b4148;
  padding: 8px;
  margin: 8px 8px 0 0;
  border-radius: 0 5px 5px 5px;
  color: #ff7675; }

/* input button */
.reg-window__input-button {
  position: absolute;
  color: #606468;
  background: #363b41;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  margin-right: 6px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: #34393e; }

  &:active {
    background: #303439; } }
