
$row-height-one: calc(100vh - 120px);
$row-height-any: calc((100vh - 140px) / 2);

.editors-wrap-any {
  padding: 0 20px 20px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: $row-height-any $row-height-any;
  grid-gap: 20px;
  margin: 0;

  .CodeMirror {
    height: 100% !important; }

  @media all and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr; }

  @media all and (max-width: 700px) {
    grid-template-columns: 1fr; }

  @media all and (max-width: 500px) {
    padding: 0; }

  #code-editor-htmlembedded-wrap {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2; }

  #code-editor-css-wrap {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3; }

  #code-editor-javascript-wrap {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3; } }

.editors-wrap-one {
  padding: 0 20px 20px 20px;
  display: grid;
  grid-template-columns: 100% 100% 100%;
  grid-template-rows: $row-height-one $row-height-one $row-height-one;
  grid-gap: 20px;
  margin: 0;
  position: relative;
  transition: .3s ease-in-out;

  .CodeMirror {
    height: 100% !important; }

  &_-1 {
    animation: min ease-in-out .2s; }

  &_0 {
    left: 0; }

  &_1 {
    left: calc(-100% + 20px); }

  &_2 {
    left: calc(-200% + 40px); }

  &_3 {
    animation: max ease-in-out .2s; } }


@keyframes max {
  0% {
    left: calc(-200% + 40px); }
  50% {
    left: calc(-200%); }
  100% {
    left: calc(-200% + 40px); } }

@keyframes min {
  0% {
    left: 0; }
  50% {
    left: 40px; }
  100% {
    left: 0; } }

.app-demo {
  width: 445px;  // 385px - iphone X
  height: 822px;
  position: absolute;
  transition: .2s;
  z-index: 201;
  margin-left: 20px;
  display: grid;
  grid-template-columns: calc(100% - 60px) 60px;
  bottom: -10px;

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    position: relative;
    z-index: 2; }

  &__emulator {
    padding: 10px 5px 10px 5px;
    border-radius: 20px;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center; }

  &__nav {
    padding-top: 60px;

    &-item {
      width: 44px;
      height: 44px;
      margin: 0 auto 16px;
      background: white;
      opacity: 0.4;
      border-radius: 2px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      transition: .1s;
      display: flex;
      justify-content: center;
      align-items: center;

      &-icon {
        color: #262c31;
        font-size: 22px; }

      &:hover {
        opacity: 0.6; }

      &:active {
        transform: scale(0.96); }

      &_true {
        opacity: 0.8; } } }

  &:hover &__emulator {
    border: none; }

  .interpreter {
    border-radius: 10px; } }

/* position mode */
.app-demo-position-false {
  bottom: -812px;

  &:hover {
    bottom: -10px !important; } }

.app-demo-position-false .app-demo__emulator {
  border-top: 2px solid #3498db; }

/* fullscreen mode */
.app-demo-fullscreen-true {
  margin-left: 0 !important;
  width: 100% !important;
  top: 0 !important;
  height: 100%;

  &:hover {
    bottom: -10px !important; } }

.app-demo-fullscreen-true {
  .app-demo__emulator {
    border-top: none;
    border-radius: 0;
    padding: 0;

    .interpreter {
      border-radius: 0; } }

  .app-demo__nav {
    background: black; } }

.app-demo__emulator_moving::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000; }

.project-page-container {
  height: 100vh;
  overflow: hidden;
  position: relative; }

.iframe-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1; }
