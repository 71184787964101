@import "./LogWrap/reg-wrap.sass";
@import "./LogWindow/reg-window.sass";
@import "../additComponents/addit-components.sass";

* {
  scrollbar-width: 0;
  &::-webkit-scrollbar {
    width: 0px;
    background: rgba(255, 255, 255, 0.0); } }

