.lending {

  height: 100vh;
  overflow: hidden;

  header, section {
    height: 100vh; }

  header {
    background: #4b6584;

    main {
      width: 600px;
      margin: 10px;

      .lending-description {
        display: block;
        padding-top: 20px;
        color: white;
        text-align: center; } } } }

.lending-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: .4s linear;

  &_down {
    position: absolute;
    margin: auto;
    bottom: 20px;
    display: flex;
    align-items: flex-end;
    z-index: 1; }

  &_space {
    width: 140px;
    height: 30px;
    background: white;
    text-align: center;
    padding-top: .5px;
    border-radius: 5px;
    box-shadow: 0 -3px rgba(0, 0, 0, .2) inset;
    margin: 0 8px;

    &__animate {
      animation: space 2s linear infinite; } }

  &_arrow {
    width: 30px;
    height: 30px;
    background: white;
    text-align: center;
    padding-top: .5px;
    border-radius: 5px;
    box-shadow: 0 -3px rgba(0, 0, 0, .2) inset;
    margin: 0 8px;
    animation: space 2s linear infinite;

    &__animate1 {
      animation-delay: .1s; }

    &__animate2 {
      animation-delay: .2s; } }

  &_swipe {
    transform: rotate(-135deg);
    width: 20px;
    height: 20px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    border-radius: 1px; }

  &_nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 4px 0;
    background: rgba(0, 0, 0, 0.2);
    letter-spacing: 1px;
    position: absolute;
    top: 0;
    left: 0;

    a {
      display: block;
      color: black;
      height: 26px;
      text-align: center;
      border-radius: 2px;
      box-shadow: 0 -3px rgba(0, 0, 0, .2) inset;
      margin-right: 4px;
      padding: 1px 8px 0 8px;
      font-weight: 400;
      font-size: 14px;
      background: #ffffff;
      text-decoration: none;

      &:hover {
        text-decoration: none; } } } }

@keyframes swipe {
  0% {
    right: 20px; }
  50% {
    right: 30px; }
  100% {
    right: 20px; } }

@keyframes space {
  50% {
    box-shadow: 0 -3px rgba(0, 0, 0, .2) inset;
    height: 30px; }
  55% {
    box-shadow: none;
    height: 27px; }
  95% {
    box-shadow: none;
    height: 27px; }
  100% {
    box-shadow: 0 -3px rgba(0, 0, 0, .2) inset;
    height: 30px; } }

.lending-section {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden; }

.lending-text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  position: absolute;
  color: white;
  font-weight: bold;

  &_title {
    font-size: 46px; }

  &_main {
    font-size: 28px;
    margin-top: 6px; } }

.lending-register {
  position: absolute;
  right: 20px;
  bottom: 20px; }

// Mobile

.lending-mobile {
  display: grid;
  grid-template-columns: repeat(6, 100vw);
  width: 600vw;
  transition: .4s ease-in;

  section, header {
    width: 100vw; }

  section {
    margin-left: -0.5px;

    .lending-icon {
      position: absolute;
      font-size: 200px;
      opacity: .3;
      bottom: 100px; } }

  .lending-text {
    position: absolute;
    left: 10px;
    top: 10px;
    width: calc(100vw - 20px);

    &_title {
      font-size: 30px;
      font-weight: bold;
      color: #313131; }

    &_main {
      font-size: 18px;
      margin-top: 8px; } }

  main {
    width: 80%; } }

.lending-mobile-wrap {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .lending-header_down {
    top: 50px; } }

.lending-description a {
  color: white;

  &:hover {
    color: #d9dff5; } }

.lending-section video {
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  filter: brightness(.7); }

/* mobile controls */
.lending-header_mobile-controls {
  position: fixed;
  right: 10px;
  bottom: 10px;
  font-size: 20px;
  z-index: 2000; }

.lending-header_mobile-button {
  width: 45px;
  height: 45px;
  border-radius: 12px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  background: #494e57;
  border: .5px solid #2f3542;
  transition: .1s;

  &:active {
    transform: scale(.95); } }

/* mobile sections view */

@media (max-width: 500px) {
  .lending-header {
    transition: 0s; }

  .lending-section {
    display: none; }

  .active-slide-5-5, .active-slide-4-4, .active-slide-3-3, .active-slide-2-2, .active-slide-1-1 {
    display: flex;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0; } }
