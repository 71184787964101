.app-navbar {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-top: 50px;

  &__block {
    display: flex;
    align-items: center;
    height: 50px; }

  &__block-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 10px;
    background: #363b40; }

  &__block-2 {
    left: 20px;
    position: absolute; } }

.app-navbar__item_name {
  font-size: 18px;
  color: #c5ccd4;
  margin-top: 4px; }

.app-navbar__item_text {
  background: #262c31;
  padding: 2px 4px;
  border-radius: 2px;
  font-family: monospace;
  font-size: 10px;
  color: white;
  display: inline-block;
  position: relative;
  top: -6px; }

.app-navbar__item-link {
  span:first-child, a {
    font-size: 18px;
    color: #8f969e;
    font-weight: 400;
    cursor: pointer;
    margin-left: 24px;
    text-decoration: none;

    &:hover {
      color: #adb5bd;
      text-decoration: none; } }

  span:nth-child(2), span:nth-child(3) {
    font-size: 15px;
    padding: 1px;
    background: #c5ccd4;
    border-radius: 2px;
    color: black;
    letter-spacing: 0;
    margin-left: 2px;
    display: inline-block;
    width: 56px;
    text-align: center; } }

.app-navbar__item-text {
  font-size: 18px;
  color: #8f969e;
  font-weight: 400;
  margin-left: 24px; }

.app-navbar__item_text-button {
  span:first-child {
    font-size: 14px;
    color: #8f969e;
    font-weight: 400;
    letter-spacing: -.5px; }

  span:nth-child(2), span:nth-child(3) {
    font-size: 13px;
    padding: 2px;
    background: #c5ccd4;
    border-radius: 2px;
    color: black;
    letter-spacing: 0;
    margin-left: 1px;
    display: inline-block;
    width: 50px;
    text-align: center; }


  span:last-child {
    margin-right: 18px; } }

.app-navbar__item_text-button_left {
  margin-left: 24px; }

.app-navbar__item-logo {
  width: 40px;
  height: 40px;
  border-radius: 20%;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #262c31;
  font-size: 16px; }

.app-navbar__item-connect {
  .app-navbar__item-text-inputs {
    display: flex;
    margin: -150px auto 0 auto;
    position: absolute;
    z-index: 10;
    background: #858b92;
    height: 110px;
    top: 0;
    padding: 10px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    border-radius: 0 0 6px 6px;
    transition: .3s;

    div {
      width: 200px;

      input {
        margin-top: 10px;
        border: none;
        background: white;
        padding-left: 4px;
        width: 200px;
        height: 30px;
        border-radius: 2px;

        &:focus {
          outline: none;
          border: none;
          border-radius: 0; } } }

    button {
      height: 70px;
      width: 40px;
      margin: 10px 0 0 10px;
      border: none;
      background: white;
      border-radius: 2px;

      &:focus {
        outline: none;
        border: none;
        border-radius: 0; } } }

  &:hover {
    .app-navbar__item-text-inputs {
      margin-top: 0; } } }
