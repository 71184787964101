


/*   ---==== main system page ====---   */

.app-group-widget {
  width: calc(100% - (100% - 240px) / (5 / 2));
  position: relative;
  padding-top: 10px;
  margin: 20px calc((100% - 240px) / (5));

  @media all and (min-width: 520px) {
    margin: 20px calc((100% - 300px) / (6)); }

  @media all and (min-width: 1080px) {
    margin: 20px calc((100% - 360px) / (7)); }

  &__title {
    font-size: 24px;
    font-weight: 300;
    color: black; }

  &__main {
    font-size: 14px;
    color: #2d2d2d;
    margin-top: 10px;

    a {
      color: #71b3e0; } } }

.main-system-page__pc-mode .app-group-widget {
  width: calc(100% - (100% - 240px) / (5 / 2)) !important;
  margin: 20px calc((100% - 240px) / (5)) !important; }

.apps-wrap {
  padding-bottom: 16px; }


.main-system-page {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  float: right;
  position: relative;
  background: #FDF6F0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6); }

.main-system-page__pc-mode .app-group__icons {
  margin-left: calc((100% - 240px) / (5 * 2)) !important; }

.groups-wrap {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 0 80px 0;
  margin: 0;
  grid-gap: 0;
  z-index: 1;
  position: relative;
  left: 0; }

.app-group {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;

  &__icons {
    width: 100%;
    margin-left: calc((100% - 240px) / (5 * 2));

    @media all and (min-width: 520px) {
      margin-left: calc((100% - 300px) / (6 * 2)); }

    @media all and (min-width: 1080px) {
      margin-left: calc((100% - 360px) / (7 * 2)); } } }

.system-wrap {
  width: 100vw;
  height: 100vh; }

.system-pc {
  display: none;

  &__active {
    float: left;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; } }

.box-title {
  position: relative;
  height: 62px;
  width: 128px;
  margin: 20px auto;

  &__img {
    filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, 0.1) );
    width: 50px;
    margin-right: -13px; }

  &__text {
    font-family: 'comfortaa', roboto, monospace;
    font-size: 32px;
    color: white;
    position: absolute;
    margin-left: 7px;
    text-shadow: .5px .5px .5px rgba(0, 0, 0, 0.1); }

  &__under-text {
    color: white;
    position: absolute;
    right: 2px;
    bottom: 0; } }

.box-main {
  margin-top: 4px;
  display: block;
  text-decoration: none;

  span:first-child {
    font-size: 16px;
    color: white;
    font-weight: 400;
    letter-spacing: -.5px;
    margin-right: 4px; }

  span:nth-child(2), span:nth-child(3) {
    font-size: 13px;
    padding: 2px;
    background: #c5ccd4;
    border-radius: 2px;
    color: black;
    letter-spacing: 0;
    margin-left: 1px;
    display: inline-block;
    width: 50px;
    text-align: center; }

  &:hover {
    color: white; } }



/*   ---==== main app page ====---   */

.main-app-page {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 10;
  transition: .2s ease-out;
  top: 0;
  transform: scale(1);
  background: white; }

.main-app-page[data-state="closed"], .main-app-page[data-state="closing"] {
  transform: scale(.2);
  top: 100vh; }

.fullview {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 54px;
  height: 54px;
  color: #262c31;
  font-size: 19px;
  background: #F5C6AA;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; }
