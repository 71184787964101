
.app-icon {
  width: 60px;
  vertical-align: top;
  display: inline-block;
  margin: 0 calc((100% - 240px) / (5 * 2));

  @media all and (min-width: 520px) {
    margin: 0 calc((100% - 300px) / (6 * 2)); }

  @media all and (min-width: 1080px) {
    margin: 0 calc((100% - 360px) / (7 * 2)); }

  //background: black

  div {
    width: 54px;
    height: 54px;
    margin: 16px auto 8px auto;
    color: #262c31;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: .06s;

    &:active {
      transform: scale(0.95); } }

  footer {
    //background: red
    word-wrap: break-word;
    width: 80px;
    margin-left: -10px;
    color: black;
    font-size: 12px;
    text-align: center; } }

.main-system-page__pc-mode .app-icon {
  margin: 0 calc((100% - 240px) / (5 * 2)) !important; }
