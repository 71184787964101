.clone-card-wrap {

  &:hover {
    .project-card__icon {
      transform: none;
      box-shadow: none; }

    .clone-info {
      cursor: pointer; } }


  .clone-info {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 100%; }

  .project-card__icon, .arrow, .parentId {
    margin: 14px; }

  .project-card__icon {
    width: 60px;
    height: 60px;
    font-size: 20px; }

  .arrow {
    color: #8e959c; }

  .parentId {
    color: #8e959c;
    font-size: 14px;
    width: auto;
    background: #292d32;
    padding: 5px;
    border-radius: 3px; } }

