
.doc__item {
  max-width: 1000px;
  min-width: 660px;
  padding: 20px;
  margin-bottom: 20px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 -3px rgba(0, 0, 0, .2) inset;

  a {
    color: black; }

  h5 {
    margin: 30px 0 10px 0; }

  mark {
    background: #ffd287; }

  @media all and (max-width: 910px) {
    margin-bottom: 10px; } }
